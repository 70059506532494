import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"

const Questions = styled.div`
`
const Question = styled.div`

`

const FAQPage = () => {
	const data = useStaticQuery(graphql`
		{
			allMysqlFaQs {
				edges {
					faq: node {
						id
						question
						answer
					}
				}
			}
		}
  	`);

	return (
		<SupportPage page="faq" title="Frequently Asked Questions">
			<PageHeader title="Frequently Asked Questions" />
			<Questions>
				{data.allMysqlFaQs.edges.map(({faq}) => (
				<Question key={faq.id}>
					<h3>{faq.question}</h3>
					<div>{faq.answer}</div>
				</Question>
				))}
			</Questions>
		</SupportPage>
	)
}

export default FAQPage